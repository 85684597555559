import styles from './index.module.less';
import moment from 'moment';
import { ImageViewer, Input, Button, Toast} from 'antd-mobile';
import { useState } from 'react';

export default function WithdrawAuditCard({record = {}, operable = true, onClickButton = () => {}}) {
    const [reasonDesc, setReasonDesc] = useState('')

    const onClickQrCode = () => {
        ImageViewer.Multi.show({
            images: [record?.feature?.WITHDRAW_REQUEST?.qrCode],
            defaultIndex: 0
        })
    }

    const onClickOperateButton = (result) => {
        if(result === 'REJECT' && !reasonDesc) {
            Toast.show({content: '请输入拒绝原因'})
            return
        }
        onClickButton({
            record: record,
            result: result,
            reasonDesc: reasonDesc
        })
    }

    return (
        <div className={styles.withdrawAuditCard}>
            <div className={styles.cardItem}>
                <div className={styles.cardItemTitle}>申请时间：</div>
                <div>{moment(record.gmtCreate).format('YYYY-MM-DD HH:mm:ss')}</div>
            </div>
            <div className={styles.cardItem}>
                <div className={styles.cardItemTitle}>提现金额：</div>
                <div>{record?.feature?.WITHDRAW_REQUEST?.amount}</div>
            </div>
            <div className={styles.cardItem}>
                <div className={styles.cardItemTitle}>真实姓名：</div>
                <div>{record?.feature?.WITHDRAW_REQUEST?.realName}</div>
            </div>
            <div className={styles.cardImageItem}>
                <div className={styles.cardItemTitle}>收款二维码：</div>
                <img className={styles.cardItemQrCode} src={record?.feature?.WITHDRAW_REQUEST?.qrCode} onClick={onClickQrCode}/>
            </div>
            <div className={`${styles.cardItem} ${record.status === 'PROCESSING' ? styles.cardItemStatus : ''}`}>
                <div className={styles.cardItemTitle}>当前状态：</div>
                <div>{record.status === 'PROCESSING' ? '审核中' : '审核完成'}</div>
            </div>
            {record?.result && <div className={styles.cardItem}>
                <div className={styles.cardItemTitle}>审核结果：</div>
                <div>{record.result === 'PASS' ? '通过（已打款）' : '拒绝'}</div>
            </div>}
            {record?.feature?.REASON_DESC && <div className={styles.cardItem}>
                <div className={styles.cardItemTitle}>审核备注：</div>
                <div>{record?.feature?.REASON_DESC}</div>
            </div>}
            { operable && record.status === 'PROCESSING' && <div className={styles.operateArea}>
                <div className={styles.operateLeft}>
                    <Input className={styles.inputBox} placeholder='拒绝需输入原因' clearable value={reasonDesc} onChange={setReasonDesc}/> 
                </div>
                <div className={styles.operateRight}>
                    <Button className={styles.operateButton} color='danger' onClick={() => onClickOperateButton('REJECT')}>拒绝</Button>
                    <Button className={styles.operateButton} color='primary' onClick={() => onClickOperateButton('PASS')}>通过</Button>
                </div>
            </div>}
        </div>
    )
}