import styles from './index.module.less'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { NavBar, List, InfiniteScroll } from 'antd-mobile'
import {requestHttpHintWhenFail} from "../../../utils/request";
import WithdrawAuditCard from '../../../components/withdraw-audit-card';

export default function WithdrawDetail() {
    const navigate = useNavigate();
    const [recordList, setRecordList] = useState([])
    const [hasMore, setHasMore] = useState(true)

    const goBack = () => {
        navigate(-1)
    }

    const loadMore = () => {
        return queryWithdrawRecords()
    }

    const queryWithdrawRecords = (start) => {
        return new Promise((resolve, reject) => {
            requestHttpHintWhenFail('/blindbox/api/queryWithdrawRecords', {
                start: Number.isFinite(start) ? start : recordList.length,
                limit: 20
            }, 'GET').then((res) => {
                if(res.data && res.data.length > 0) {
                    setRecordList([...recordList, ...res.data])
                    if(!hasMore) {
                        setHasMore(true)
                    }
                } else {
                    if(hasMore) {
                        setHasMore(false)
                    }
                }
                resolve()
            }).catch(err => reject(err))
        })
    }

    useEffect(() => {
        queryWithdrawRecords()
    }, [])

    const onClickCardButton = (params) => {
        requestHttpHintWhenFail('/blindbox/api/processWithdrawRecord', {
            auditId: params.record.id,
            result: params.result,
            reasonDesc: params.reasonDesc
        }, 'POST').then(() => {
            recordList.forEach(record => {
                if(record.id === params.record.id) {
                    record.status = 'COMPLETED'
                    record.result = params.result
                    record.feature.REASON_DESC = params.reasonDesc
                }
            })
            setRecordList([...recordList])
        })
    }

    return (
        <div>
            <NavBar onBack={goBack}>提现记录</NavBar>
            {(!recordList || recordList.length === 0) && <div className={styles.emptyDesc}>暂无记录</div>}
            {recordList && recordList.length > 0 && <div className={styles.recordList}>
                <List>
                    {recordList.map(record => 
                        <List.Item key={record.id}>
                           <WithdrawAuditCard record={record} operable={false} onClickButton={onClickCardButton}/>
                        </List.Item>)}
                </List>
                <InfiniteScroll loadMore={loadMore} hasMore={hasMore}/>
            </div>}
        </div>
    )
}