import styles from './index.module.less';
import { useLocation } from "react-router-dom";
import { useState, useEffect } from 'react';
import { Button, Toast } from 'antd-mobile';
import qs from "query-string";
import RechargeCard from '../../components/recharge-card';
import MemberRechargeCard from '../../components/member-recharge-card';
import InputPopup from '../../components/input-popup';
import UserInfoCard from '../../components/user-info-card';
import requestHttp from "../../utils/request";

let currentCheckedCode = null
let currentMemberCheckedCode = null
let openId = null

function CircleRecharge() {
  const {search} = useLocation() 
  const {code, appName, theme, checkedCode} = qs.parse(search)
  document.documentElement.setAttribute('data-prefers-color-scheme', theme)
  const [cardInfos, setCardInfos] = useState([])
  const [memberCardInfos, setMemberCardInfos] = useState([])
  const [buttonLoading, setButtonLoading] = useState(false)
  const [memberButtonLoading, setMemberButtonLoading] = useState(false)
  const [inputPopupVisible, setInputPopupVisible] = useState(false)
  const [userInfo, setUserInfo] = useState({})
  const finalAppName = appName ? appName : 'tm_star_pa'
  useEffect(() => {
    document.title = '树洞星球充值';
    requestHttp('/circle/api/queryRechargeSkus').then(res => {
      if(res.data) {
        if(checkedCode) {
          res.data.forEach(cardInfo => {
            if(cardInfo.code === checkedCode) {
              currentCheckedCode = checkedCode
              cardInfo.checked = true
            }
          })
        }
        if(!currentCheckedCode){
          //默认选中第一个
          currentCheckedCode = res.data[0].code
          res.data[0].checked = true
        }
        setCardInfos(res.data)
      }
    }).catch(err => console.log(err))
    requestHttp('/circle/api/queryMemberRechargeSkus').then(res => {
      if(res.data) {
        if(!currentMemberCheckedCode){
          //默认选中第一个
          currentMemberCheckedCode = res.data[0].code
          res.data[0].checked = true
        }
        setMemberCardInfos(res.data)
      }
    }).catch(err => console.log(err))

    if(null == code) {
      Toast.show({
        icon: 'fail',
        content: '微信授权失败，请重试',
        duration: 2000
      })
    } else {
      //根据code查询用户信息
      requestHttp('/circle/api/queryUserInfoByLoginCode', {
        loginCode: code,
        appName: finalAppName
      }).then((res) => {
        if(res.data) {
          openId = res.data.openId
          if(res.data.userInfo) {
            setUserInfo(res.data.userInfo)
          } else {
            setInputPopupVisible(true)
          }
        } else {
          Toast.show({
            icon: 'fail',
            content: '微信授权失败，请重试',
            duration: 2000
          })
        }
      }).catch((errorMsg) => [
        Toast.show({
          icon: 'fail',
          content: '微信授权失败，请重试',
          duration: 2000
        })
      ])
    }
  }, [])

  const onClickCard = (cardInfo) => {
    cardInfos.forEach(card => {
      if(card.code === cardInfo.code) {
        card.checked = true
        currentCheckedCode = card.code
      } else {
        card.checked = false
      }
      setCardInfos([...cardInfos])
    })
  }

  const onClickMemberCard = (cardInfo) => {
    memberCardInfos.forEach(card => {
      if(card.code === cardInfo.code) {
        card.checked = true
        currentMemberCheckedCode = card.code
      } else {
        card.checked = false
      }
      setMemberCardInfos([...memberCardInfos])
    })
  }

  const onBridgeReady = (data) => {
    window.WeixinJSBridge.invoke('getBrandWCPayRequest', {
        "appId": data.appId,     //公众号ID，由商户传入     
        "timeStamp": data.timeStamp,     //时间戳，自1970年以来的秒数     
        "nonceStr": data.nonceStr,      //随机串     
        "package": data.packageVal,
        "signType": data.signType,     //微信签名方式：     
        "paySign": data.paySign //微信签名 
    },
    (res) => {
        if (res.err_msg === "get_brand_wcpay_request:ok") {
            // 使用以上方式判断前端返回,微信团队郑重提示：
            //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
            Toast.show({
              icon: 'success',
              content: '支付成功',
              duration: 1000
            })
        } else if(res.err_msg === "get_brand_wcpay_request:cancel") {
            //支付取消, do nothing
        } else {
          Toast.show({
            icon: 'fail',
            content: res.err_msg,
            duration: 2000
          })
        }
    })
  }

  const onClickOpenButton = () => {
    createRechargeOrderH5(currentCheckedCode, setButtonLoading)
  }

  const onClickOpenMemberButton = () => {
    createRechargeOrderH5(currentMemberCheckedCode, setMemberButtonLoading)
  }

  const createRechargeOrderH5 = (skuCode, setLoadingVariable = () => {}) => {
    if(null == openId) {
      Toast.show({
        icon: 'fail',
        content: '微信授权失败，请重试',
        duration: 2000
      })
      return
    }
    if(null == userInfo.userId) {
      setInputPopupVisible(true)
      return
    }
    setLoadingVariable(true)
    requestHttp('/circle/api/createRechargeOrderH5', {
      appName: finalAppName,
      openId: openId,
      userId: userInfo.userId,
      skuCode: skuCode
    }, 'POST').then(res => {
      setLoadingVariable(false)
      if (typeof WeixinJSBridge === "undefined") {
        if (document.addEventListener) {
            document.addEventListener('WeixinJSBridgeReady', () => onBridgeReady(res.data), false);
        } else if (document.attachEvent) {
            document.attachEvent('WeixinJSBridgeReady', () => onBridgeReady(res.data));
            document.attachEvent('onWeixinJSBridgeReady', () => onBridgeReady(res.data));
        }
      } else {
          onBridgeReady(res.data);
      }
      
    }).catch(errorMsg => {
      setLoadingVariable(false)
      Toast.show({
        icon: 'fail',
        content: errorMsg ? errorMsg : '支付失败',
        duration: 1000
      })
    })
  }

  const onClickInputPopupButton = (inputValue) => {
    //查询该用户ID是否有效
    requestHttp('/circle/api/queryUserInfoById', {
      userId: inputValue
    }, 'GET').then(res => {
      setUserInfo(res.data)
      setInputPopupVisible(false)
    }).catch(errorMsg => {
      Toast.show({
        icon: 'fail',
        content: '用户ID不存在，请重新输入',
        duration: 2000
      })
    })
  }

  const onInputPopupMaskClick = () => {
    if(null != userInfo.userId) {
      setInputPopupVisible(false)
    }
  }

  const onClickSwitchUserButton = () => {
    setInputPopupVisible(true)
  }

  return (
    <div className={styles.pageBody}>
      {userInfo.userId != null && <div>
        <div className={styles.userInfoHintMsg}>正在为当前用户充值</div>
        <UserInfoCard
          theme={theme} 
          userNick={userInfo.userNick} 
          avatar={userInfo.avatar}
          userId={userInfo.userId}
          gender={userInfo.gender}
          buttonName="切换账户"
          onClickButton={onClickSwitchUserButton}/>
      </div>}
      <div className={styles.rechargeCoinDesc}>
        <div>开通会员</div>
      </div>
      <div className={styles.rechargeCardList}>
        {memberCardInfos.map(memberCardInfo => {
          return <MemberRechargeCard key={memberCardInfo.code} theme={theme} cardInfo={memberCardInfo} onClick={onClickMemberCard}/>
        })}
      </div>
      <div className={styles.memberDescArea}>
        <div className={styles.memberDecWrapper}>
          <div className={styles.memberDescTitleLeft}></div>
          <div className={styles.memberDescTitle}>会员权益</div>
        </div>
        <div className={styles.memberDescItem}>
          <div className={styles.smallPoint}></div>
          <div className={styles.memberDescText}>每日登录额外奖励10金币</div>
        </div>
        <div className={styles.memberDescItem}>
          <div className={styles.smallPoint}></div>
          <div className={styles.memberDescText}>首页支持查看同城动态</div>
        </div>
        <div className={styles.memberDescItem}>
          <div className={styles.smallPoint}></div>
          <div className={styles.memberDescText}>小纸条支持高级筛选</div>
        </div>
        <div className={styles.memberDescItem}>
          <div className={styles.smallPoint}></div>
          <div className={styles.memberDescText}>发现页支持高级筛选</div>
        </div>
        <div className={styles.memberDescItem}>
          <div className={styles.smallPoint}></div>
          <div className={styles.memberDescText}>展示会员专属头像标识</div>
        </div>
      </div>
      <Button className={styles.openMemberButton}
        loading={memberButtonLoading} 
        block 
        color='primary'
        size='large' 
        onClick={onClickOpenMemberButton}>立即开通</Button>
      <div className={`${styles.rechargeCoinDesc} ${styles.rechargeAreaGap}`}>
        <div>充值金币</div>
        <div className={styles.rechargeCoinSubDesc}>（不含会员权益）</div>
      </div>
      <div className={styles.rechargeCardList}>
        {cardInfos.map(cardInfo => {
          return <RechargeCard key={cardInfo.code} theme={theme} cardInfo={cardInfo} onClick={onClickCard}/>
        })}
      </div>
      <Button className={styles.openButton}
        loading={buttonLoading} 
        block 
        color='primary'
        size='large' 
        onClick={onClickOpenButton}>立即充值</Button>
      <div className={styles.noticeDesc}>如支付完成后一直没有生效，退出小程序重新进入即可</div>
      <InputPopup 
        theme={theme}
        visible={inputPopupVisible}
        onClickButton={onClickInputPopupButton}
        onMaskClick={onInputPopupMaskClick}
        title='请输入需要充值的用户ID'
        subTitle='用户ID可在小程序内"我的"页面查看'
        placeholder='请输入用户ID'/>
    </div>
  );
}
  
export default CircleRecharge;