import styles from './index.module.less';

export default function MemberRechargeCard({theme='light', cardInfo = {}, onClick = () => {}}) {

    return (
        <div className={`${styles[theme]} ${styles.rechargeCard} ${cardInfo.checked ? styles.rechargeCardActive: ''}`}
            onClick={() => onClick(cardInfo)}>
        <div className={styles.rechargeCardInner}>
            <div className={styles.rechargeCardTopLine}>
                <div className={styles.rechargeCardTimeDesc}>{cardInfo.timeDesc}</div>
                <div className={styles.rechargeCardTimeUnit}>{cardInfo.timeUnitDesc}</div>
            </div>
            <div className={styles.rechargeCardPriceLine}>{'¥ ' + cardInfo.price}</div>
            <div className={styles.rechargeCardDescLine}>{cardInfo.desc}</div>
        </div>
        </div>
    )
}