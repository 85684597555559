import styles from './index.module.less'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { NavBar, Input, Button, Toast} from 'antd-mobile'
import { useLocation } from "react-router-dom";
import qs from "query-string";
import {requestHttpHintWhenFail} from "../../../utils/request";
import ImageCompressUploader from "../../../components/image-compress-uploader";

export default function WithdrawApply() {
    const navigate = useNavigate();
    const location = useLocation()
    const {remainWithdraw} = qs.parse(location.search)
    const [withdrawValue, setWithdrawValue] = useState()
    const [realName, setRealName] = useState('')
    const [fileList, setFileList] = useState([])
    const [buttonLoading, setButtonLoading] = useState(false)

    const goBack = () => {
        navigate(-1)
    }

    const onClickSubmitButton = () => {
        if(!withdrawValue) {
            Toast.show({content: '请输入提现金额'})
            return
        }
        if(withdrawValue <= 0) {
            Toast.show({content: '可提现余额不足'})
            return
        }
        if(!realName) {
            Toast.show({content: '请输入真实姓名'})
            return
        }
        if(!fileList || fileList.length === 0) {
            Toast.show({content: '请上传收款码'})
            return
        }
        setButtonLoading(true)
        requestHttpHintWhenFail('/blindbox/api/submitWithdrawApply', {
            amount: withdrawValue,
            realName: realName,
            qrCode: fileList[0].url
        }, 'POST').then(() => {
            setButtonLoading(false)
            setTimeout(() => {
                navigate('/h5/blind-box/withdraw-detail');
            }, 1000)
            Toast.show({content: '提交成功，请耐心等待。'})
            
            setWithdrawValue('')
            setRealName('')
            setFileList([])
        }).catch(() => {
            setButtonLoading(false)
        })
    }

    return (
        <div>
            <NavBar onBack={goBack}>申请提现</NavBar>
            <div className={styles.withdrawApplyPage}>
                {isFinite(remainWithdraw) && <div className={styles.hintText}>当前可提现总金额：{remainWithdraw}元</div>}
                <div className={styles.inputItem}>
                    <div className={styles.inputItemTitle}>提现金额</div>
                    <Input placeholder='请输入' clearable type='number' min={0.01} max={remainWithdraw} 
                        value={withdrawValue} onChange={val => setWithdrawValue(val)}/>
                </div>
                <div className={styles.divideLine}/>
                <div className={styles.inputItem}>
                    <div className={styles.inputItemTitle}>真实姓名</div>
                    <Input placeholder='请输入收款人真实姓名' clearable value={realName} onChange={val => setRealName(val)}/>
                </div>
                <div className={styles.divideLine}/>
                <div className={styles.imageItem}>
                    <div className={styles.inputItemTitle}>收款码</div>
                    <ImageCompressUploader api='/blindbox/api/uploadPhoto' fileList={fileList} maxCount={1} onChange={setFileList}/>
                    <div className={styles.hintText}>请务必提供和真实姓名一致的收款账号二维码</div>
                </div>
                <div className={styles.reminderDoc}>
                    <div className={styles.reminderDocTitle}>温馨提示（提现必看）</div>
                    <div className={styles.reminderDocText}>1.因为每次提现都需人工操作打款，为减少人工操作次数所以请尽量大额提现。</div>
                    <div className={styles.reminderDocText}>2.之所以需要真实姓名是因为打款时需要验证。</div>
                    <div className={styles.reminderDocText}>3.请务必保证收款码的正确性，如因收款码错误导致的打款错误本平台概不负责。</div>
                </div>
                <Button className={styles.submitButton} 
                    loading={buttonLoading} 
                    disabled={buttonLoading} 
                    size='large' 
                    block
                    onClick={onClickSubmitButton}
                    color='warning'>提交申请</Button>
            </div>
        </div>
    )
}