import styles from './index.module.less'
import { useState, useEffect, useRef } from 'react'
import { Image, Input, Toast, ImageViewer, Button } from 'antd-mobile'
import { Dialog } from 'react-vant';
import { requestHttpHintWhenFail } from '../../../utils/request'
import { launchWxPayByJSBridge } from '../../../utils/comment';
import { useSelector } from 'react-redux';
import zhaomuPoster from '../../../images/blind-box/zhaomu-poster.jpeg'
import incomeDataImage from '../../../images/blind-box/income-data.jpg'
import incomeDetailImage from '../../../images/blind-box/income-detail.jpg'
import partnerTypeImage from '../../../images/blind-box/partner-type-desc.jpg'

const viewerImages = [incomeDataImage, incomeDetailImage, partnerTypeImage]

export default function JoinPartner() {
    const [pageHeight, setPageHeight] = useState(300)
    const pageRef = useRef()
    const [inputValue, setInputValue] = useState('')
    const [buttonLoading1, setButtonLoading1] = useState(false)
    const [buttonLoading2, setButtonLoading2] = useState(false)
    const sourceUserId = useSelector((state) => state.global.sourceUserId)
    useEffect(() => {
        //动态计算切换标签以下的页面剩余高度
        if(pageRef.current) {
            const rect = pageRef.current.getBoundingClientRect()
            setPageHeight(window.innerHeight - rect.top - 20)
        }
    }, [])

    const onClickJoinButton = (skuCode) => {
        if(!inputValue) {
            Toast.show('请输入联系方式')
            return
        }
        startButtonLoading(skuCode)
        requestHttpHintWhenFail('/blindbox/api/joinPartnerPrePay', {
            contact: inputValue,
            sourceUserId: sourceUserId,
            skuCode: skuCode
        }, 'POST').then(res => {
            cancelButtonLoading(skuCode)
            setInputValue('')
            let env = launchWxPayByJSBridge(res.data, () => {
                onPaySuccess()
            })
            if(env === 'miniprogram') {
                Dialog.confirm({
                    title: '温馨提示',
                    message: '是否支付成功？如支付过程中遇到问题可联系客服处理哦',
                    showConfirmButton: true,
                    showCancelButton: true,
                    confirmButtonText: '已支付成功',
                    cancelButtonText: '支付有问题'
                }).then(res => {
                    onPaySuccess()
                }).catch(res => {
                    Toast.show({content: '请点击页面下方按钮联系客服', duration: 2000})
                })
            }
        }).catch(errorMsg => {
            cancelButtonLoading(skuCode)
        })
    }

    const startButtonLoading = (skuCode) => {
        if(skuCode === 'OPEN_PARTNER') {
            setButtonLoading1(true)
        } else {
            setButtonLoading2(true)
        }
    }

    const cancelButtonLoading = (skuCode) => {
        if(skuCode === 'OPEN_PARTNER') {
            setButtonLoading1(false)
        } else {
            setButtonLoading2(false)
        }
    }

    const onPaySuccess = () => {
        Toast.show({content: '支付成功，客服专员会在一个工作日内与您联系！', duration: 3000})
    }

    const onClickImage = (index) => {
        ImageViewer.Multi.show({
            images: viewerImages,
            defaultIndex: index
        })
    }

    return (
        <div ref={pageRef} className={styles.joinPartnerPage} style={{height: `${pageHeight}px`}}>
            <Image className={styles.posterImage} src={zhaomuPoster}/>
            <div className={styles.contentWrapper}>
                <div className={styles.contentText}>你是否想要拥有一个靠谱的副业？</div>
                <div className={styles.contentText}>你是否希望获得一份自由的工作？</div>
                <div className={styles.contentText}>你是否渴望成就一番自己的事业？</div>
                <div className={styles.contentText}>无论你是<span className={styles.boldText}>大学生、白领、宝妈</span>，还是任何希望通过简单操作赚取额外收入的人，这个机会不容错过！</div>
                <div className={styles.contentTitle}>合伙人能获得什么？</div>
                <div className={styles.contentText}>成为合伙人，我们会为您开通<span className={styles.boldText}>合伙人后台权限</span>，并为您提供推广物料（专属二维码、链接、海报等），通过您的推广进来的客户所产生的<span className={styles.boldText}>消费分佣</span>（分佣比例见下图表格）计入您的账户，合伙人后台可实时查看佣金收益明细并可随时提现。</div>
                <div className={styles.contentTitle}>怎么推广？</div>
                <div className={styles.contentText}>我们给到您的推广物料，您可以发布在各个平台（微信群、朋友圈、抖音、微博、知乎、小红书、QQ等）进行推广，还可以<span className={styles.boldText}>线下摆个摊</span>，推广的方式多种多样，非常适合大学生、宝妈、自媒体博主来做。</div>
                <div className={styles.contentTitle}>收益如何？</div>
                <div className={styles.contentText}>推广越多，收益越多，多劳多得。我们无法承诺您一天可以获得多少收入，但是我们相信，天道酬勤，付出总会有回报，只要跑起来就会有风！</div>
                <div className={styles.contentText}>下面是我们某合伙人的推广收益展示：</div>
                <div className={styles.contentImageWrapper}>
                    <Image className={styles.incomeImage} src={incomeDataImage} onClick={() => onClickImage(0)}/>
                    <Image className={styles.incomeImage} src={incomeDetailImage} onClick={() => onClickImage(1)}/>
                </div>
                <div className={styles.contentTitle}>普通合伙人和高级合伙人的区别？</div>
                    <Image className={styles.partnerTypeImage} src={partnerTypeImage} onClick={() => onClickImage(2)}/>
                <div className={styles.contentTitle}>怎么加入？</div>
                <div className={styles.contentText}>下方填写您的联系方式，并支付押金即可（累计收益达标后押金可退，相当于<span className={styles.boldText}>免费加入</span>）。我们的客服专员会在您支付后尽快与您取得联系。</div>
            </div>
            <div className={styles.contactWrapper}>
                <div className={styles.contentTitle}>联系方式：</div>
                <Input className={styles.contactInput} placeholder='微信、手机号都可以' value={inputValue} onChange={setInputValue} />
            </div>
            <div className={styles.joinButton}>
                <Button loading={buttonLoading1} disabled={buttonLoading1} color='primary' size='large' onClick={() => onClickJoinButton('OPEN_PARTNER')}>普通合伙人</Button>
                <Button loading={buttonLoading2} disabled={buttonLoading2} color='primary' size='large' onClick={() => onClickJoinButton('OPEN_ADVANCED_PARTNER')}>高级合伙人</Button>
            </div>
        </div>
    )
}
