
import { useState } from 'react';
import Compressor from 'compressorjs';
import { ImageUploader, Toast } from 'antd-mobile'
import requestHttp from "../../utils/request";

/**
 * 图片压缩后上传组件
 * @param {*} param0 
 * @returns 
 */

export default function ImageCompressUploader({fileList = [], maxCount = 0, multiple = false, sizeLimit = 5, api, paramName = 'photo', onChange=() => {}}) {

    const [compressedCnt, setCompressedCnt] = useState(0)

    const compressImage = (file) => {
        return new Promise((resolve, reject) => {
            new Compressor(file, {
                quality: 0.6,
                // The compression process is asynchronous,
                // which means you have to access the `result` in the `success` hook function.
                success: (result) => {
                    // 将 Blob 转换为 File
                    resolve(new File([result], file.name, {
                        type: result.type, // 继承 Blob 的 MIME 类型
                        lastModified: Date.now(), // 设置最后修改时间
                    }))
                },
                error(err) {
                    reject(err)
                },
            })
        })
    }

    const uploadImage = (file) => {
        return new Promise((resolve, reject) => {
            let paramMap = {}
            paramMap[paramName] = file
            // 上传
            requestHttp(api, paramMap, 'POST', { 'content-type': 'multipart/form-data' })
            .then(res => {
                file.uploadInfo = res.data
                resolve(file)
            }).catch(errorMsg => {
                reject(errorMsg)
            })
        })
    }

    const beforeUploadImage = (file) => {
        if (file.size > sizeLimit * 1024 * 1024) {
            Toast.show({content: `请选择小于${sizeLimit}M的图片`})
            return null
        }
        if(compressedCnt === 0) {
            Toast.show({
                icon: 'loading',
                content: '',
                duration: 5000
            })
        }
        setCompressedCnt(compressedCnt + 1)
        return new Promise((resolve, reject) => {
            compressImage(file)
            .then(photoFile => uploadImage(photoFile))
            .then(photoFile => {
                if(compressedCnt <= 1) {
                    Toast.clear()
                }
                setCompressedCnt(compressedCnt - 1)
                resolve(photoFile)
            })
            .catch(errorMsg => {
                if(compressedCnt <= 1) {
                    Toast.clear()
                }
                setCompressedCnt(compressedCnt - 1)
                setTimeout(() => {
                    Toast.show({
                        icon: 'fail',
                        content: errorMsg,
                        duration: 2000
                    })
                }, 200)
                resolve()
            })
        })
    }

    const onUploadImage = (file) => {
        return file.uploadInfo
    }
    
    return (
        <ImageUploader
            value={fileList}
            maxCount={maxCount}
            multiple={multiple}
            onChange={onChange}
            beforeUpload={beforeUploadImage}
            upload={onUploadImage}/>
    )
}